<template>
  <div>
    <!-- 상단 네비게이션 바 -->
    <nav
      v-show="$store.state.isLogin"
      class="navbar navbar-expand-lg navbar-dark bg-primary"
      id="navsmenu"
    >
      <span class="navbar-toggler-icon" @click="toggleMenu"></span>
      <span
        style="color: white; font-weight: 600; font-size: 20px; text-align: left"
        @click="this.$router.push('/dashboard')"
        data-toggle="collapse"
        data-target="#navbarText"
      >
        청년부 만나
      </span>
      <div style="width: 40%; text-align: right; color: white; font-size: 14px;">
        <span v-if="$store.state.classid"> 청 {{ $store.state.classid }} </span>
        {{ $store.state.name }} ({{ gradename($store.state.grade) }})
      </div>
    </nav>

    <!-- 슬라이드 메뉴 -->
    <transition name="slide" @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div v-if="isMenuOpen" class="menu-overlay" @click="toggleMenu"></div>
    </transition>
  
    <transition name="slide" @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div v-if="isMenuOpen" class="slide-menu" style="width: calc(100% / 2); overflow-y: scroll;">
        <button
          type="button"
          class="close-btn btn btn-link text-primary"
          @click="toggleMenu"
        >
          <i class="bi bi-x-lg"></i>
        </button>
        <div class="list-group">
          <ul class="list-group list-group-flush" style="margin-top: 50px;">
            <li class="list-group-item list-group-item-action list-group-item-light">
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/dashboard')"
              >
                Home
              </button>
            </li>
            <li class="list-group-item list-group-item-action list-group-item-light">
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/studentlist')"
              >
                셀원목록
              </button>
            </li>
            <li class="list-group-item list-group-item-action list-group-item-light">
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/studentassign')"
              >
                셀원편성
              </button>
            </li>
            <li
              class="list-group-item list-group-item-action list-group-item-light"
              v-show="$store.state.grade > 3"
            >
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/leaderassign')"
              >
                리더편성
              </button>
            </li>
            <li
              class="list-group-item list-group-item-action list-group-item-light"
              v-show="$store.state.grade > 3"
            >
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/staffassign')"
              >
                패밀리편성
              </button>
            </li>            
            <li
              class="list-group-item list-group-item-action list-group-item-light"
              v-show="$store.state.grade > 5"
            >
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/memberassign')"
              >
                전체명단
              </button>
            </li>            
            <li class="list-group-item list-group-item-action list-group-item-light">
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/attendance')"
              >
                셀 보고서
              </button>
            </li>
            <li class="list-group-item list-group-item-action list-group-item-light">
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/weeklyreport')"
              >
                셀보고현황
              </button>
            </li>
            <li class="list-group-item list-group-item-action list-group-item-light">
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/attendtotal')"
              >
                출결집계
              </button>
            </li>
            <li class="list-group-item list-group-item-action list-group-item-light">
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/attendallcount')"
              >
                전체출결
              </button>
            </li>
            <li class="list-group-item list-group-item-action list-group-item-light">
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/notice')"
              >
                부서 공지
              </button>
            </li>
            <li class="list-group-item list-group-item-action list-group-item-light">
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/allnotice')"
              >
                전체 공지
              </button>
            </li>            
            <li class="list-group-item list-group-item-action list-group-item-light">
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/study')"
              >
                셀 자료
              </button>
            </li>
            <li
              class="list-group-item list-group-item-action list-group-item-light"
              v-show="$store.state.grade > 2"
            >
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/staffreports')"
              >
                셀 보고현황(스텝용)
              </button>
            </li>            
            <li
              class="list-group-item list-group-item-action list-group-item-light"
              v-show="$store.state.grade > 3"
            >
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/reports')"
              >
                셀 보고현황
              </button>
            </li>
            <li
              class="list-group-item list-group-item-action list-group-item-light"
              v-show="$store.state.grade > 3"
            >
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/personreport')"
              >
                셀원별 보고서
              </button>
            </li>
            <li class="list-group-item list-group-item-action list-group-item-light">
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/pray')"
              >
                중보기도수첩
              </button>
            </li>
            <li class="list-group-item list-group-item-action list-group-item-light">
              <button
                type="button"
                class="btn btn-link text-primary btn-block"
                @click="navigateTo('/logout')"
              >
                로그아웃
              </button>
            </li>
          </ul>
        </div>
      </div>
    </transition>

    <!-- 메인 콘텐츠 -->
    <div class="container mt-3">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  created() {
    this.$store.dispatch("checkLoginState");
  },
  methods: {
    gradename(grade){

      if(grade ==="2") return "리더"
      if(grade ==="3") return "스텝"
      if(grade ==="4") return "교역자"
      if(grade ==="6") return "관리자"

    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    navigateTo(route) {
      this.$router.push(route);
      this.toggleMenu();
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    beforeEnter(el) {
      el.style.transform = 'translateX(-100%)';
    },
    enter(el, done) {
      setTimeout(() => {
        el.style.transition = 'transform 0.4s ease';
        el.style.transform = 'translateX(0)';
        done();
      });
    },
    leave(el, done) {
      el.style.transition = 'transform 0.4s ease';
      el.style.transform = 'translateX(-100%)';
      setTimeout(() => done(), 400);
    }
  },
};
</script>

<style scoped>
body {
  font-family: "Noto Sans KR", "Helvetica Neue", sans-serif;
}

a {
  color: #369;
  text-decoration: none;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.slide-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 2;
  padding: 1rem;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

#navsmenu span {
  margin-left: 20px;
  text-align: left;
}

.btn-link {
  color: #007bff;
  border: 0px solid #ccc;
  text-align: left;
}

.btn-link:hover {
  color: #0056b3;
  border-color: #0056b3;
  text-decoration: none;
}

.list-group-item {
  padding-top: 2px;
  padding-left: 2px;
  padding-bottom: 2px;
  padding-right: 2px;
  text-align: left;
}

.list-group-item-action:hover {
  background-color: #f0f0f0; /* 마우스 오버 시 배경색 변경 */
}
</style>
