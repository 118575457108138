import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import router from "./router";
import "bootstrap-icons/font/bootstrap-icons.css";
import Toaster from "@meforma/vue-toaster";
import store from "./store";
import dotenv from "dotenv";
import VueExcelXlsx from "vue-excel-xlsx";
import VueCookies from "vue3-cookies";
import Paginate from "vuejs-paginate-next";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

//import './registerServiceWorker'


// FCM 토큰 요청
const firebaseConfig = {
  apiKey: "AIzaSyBYg_pLQZhIN1DJ_tvOLP8E938E0J5oYDY",
  authDomain: "dongan-youth-manna.firebaseapp.com",
  projectId: "dongan-youth-manna",
  storageBucket: "dongan-youth-manna.appspot.com",
  messagingSenderId: "544894730424",
  appId: "1:544894730424:web:72e037c59b5ff4d66ab44d",
  measurementId: "G-L38PRRW5T9"
};

// Firebase 초기화
initializeApp(firebaseConfig);

// FCM 설정
const messaging = getMessaging();

getToken(messaging, { vapidKey: 'BKUbGoOe9UBTxIbCSKuQnACE25UGZ5J5rV11qE6nAuNVgiythp5-oymMgM9RcX8r6iTNA6T_77Cg13Bq-xReuBE' }).then((currentToken) => {
  if (currentToken) {
    console.log('FCM Token:', currentToken);
    // FCM 토큰을 서버로 전송하거나 필요에 따라 처리
  } else {
    console.warn('No registration token available. Request permission to generate one.');
  }
}).catch((err) => {
  console.error('An error occurred while retrieving token. ', err);
});

const showNotification = (title, options) => {
  if (Notification.permission === 'granted') {
    new Notification(title, options);
  } else {
    console.warn('Notification permission is not granted.');
  }
};

// Foreground 메시지 수신 처리
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // 알림 표시 등 작업 수행
  // 알림 옵션 설정
  const { notification } = payload;
  const title = notification.title;
  const options = {
    body: notification.body,
    icon: notification.icon
  };

  // 알림 표시
  showNotification(title, options);

});

// 서비스 워커 등록 코드
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

dotenv.config();

// Vuex 스토어 생성

createApp(App)
  .use(router)
  .use(Toaster)
  .use(store)
  .use(VueExcelXlsx)
  //.use(fb)  
  .use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "https://manna.donganyouth.com/",
    secure: false,
    sameSite: "None",
  })
  .component("paginate", Paginate)
  .mount("#app");

